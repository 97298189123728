*{
    margin: 0px;
    padding: 0px;
  }
  .cards > .container{
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
  } 

  .cards .container  p{
    font-size: 16px ;
    font-weight: 700;
    color: var(--text-gray);   
  }
  
  .cards .container .body .card-debit p{
    font-size: 16px ;
    font-weight: 500;
    color: var(--info-color);
    margin: 34px 14px 0px;
  }

  .cards .body .card-debit img {
    margin: 11px 0px 10px 0px;
    width: 321px;
    height: 209px;
    border-radius: 30px;
  }
  
  
  .cards .body{
    background: white;
    padding: 20px 24px 37px 42px;
    border-radius: 8px ;
  }
  
  
  .cards .body h1{
    color:var(--auxiliary-color);
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
  }
  
  .cards .body h2{
    color:var(--primary-color);
    font-size: 20px;
    font-weight: 500;
    
  }

  
  .cards .body .card-debit h2{
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
  }
  
  

  
  
  
  
  
  
  