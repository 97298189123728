.pagina-extrato{
    margin-left: 20%;
}

.pagina-extrato > .container{
    background: var(--bg-secondary-color);
    padding: 0px 54px 59px 28px;
} 

.pagina-extrato .extrato .col-xl-6 {
    width: 58%;
}

.pagina-extrato .extrato .col-xl-6 p{
    font-size: 16px ;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.pagina-extrato .extrato .col-xl-2{
    width: 227px;
}
.loader-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-extrato .extrato .col-xl-2 button{
    border: none;
    padding: 6px 17px 6px 17px;
    border-radius: 4px ;
    background: var(--secondary-color);
    color: white;
    font-size: 14px ;
    font-weight: 700;
    margin: 15px 0px 0px 4px;
}

.pagina-extrato .extrato .col-xl-2 .saida{
    border: none;
    padding: 7px 17px 7px 17px;
    border-radius: 4px ;
    background: #FFFFFF;
    color: var(--secondary-color);
    font-size: 14px ;
    font-weight: 700;
    margin: 15px 0px 0px 7px;
}

.pagina-extrato .extrato .col-xl-2.data{
    padding: 20px 0px 0px 0px;
}

.pagina-extrato .extrato select{
    border: none;
    background: var(--bg-secondary-color);
    color: var(--text-gray);
    font-size: 14px;
    font-weight: 500;
}

.pagina-extrato .fundo-lancamento{
    background: white;
    padding: 20px 24px 37px 42px;
    border-radius: 8px ;
}

.pagina-extrato .fundo-lancamento .col-xl-6 h1{
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-color);    
    margin-top: 16px;
    padding: 0px 0px 0px 21px;
}

    .pagina-extrato .fundo-lancamento .col-xl-6 p{
        font-size: 14px;
        font-weight: 700;
        color: var(--info-color);
        padding: 10px 0px 0px 17px;
        margin-bottom: 0;
    }

.pagina-extrato .fundo-lancamento .scroll.col-xl-6{
    overflow-y: scroll;
    margin-top: 20px;
    height: 568px;
}

.pagina-extrato .fundo-lancamento .lancamento{
    border-radius: 16px;
    width: 448px;
    padding: 10px 13px 7px 13px;
    box-shadow: 0px 8px 40px rgb(0 0 0 / 5%);
    margin-top: 10px;
}

.pagina-extrato .fundo-lancamento .lancamento .lancamento-title{
    display: flex;
    justify-content: space-between;
}

.pagina-extrato .fundo-lancamento .lancamento .lancamento-filter-icon{
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.pagina-extrato .fundo-lancamento .lancamento .lancamento-filter-icon .filter-button{
    background: transparent;
    border: none;
}

.filter-area{
    box-shadow: 0px 8px 40px rgb(0 0 0 / 5%);
    border-radius: 16px;
    padding: 10px 13px 7px 13px;
    height: 100%;
}

.filter-area .filter-area-header{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-area .filter-area-body{
    height: 30%;
    display: flex;
    flex-direction: column;
}

.filter-area .filter-area-body .filter-inputs{
    margin-top: 20px;
    display: flex;
    gap: 5px;
}

.filter-area .filter-area-body .filter-inputs label{
    width: 20%;
}

.filter-area .filter-area-body .filter-inputs select{
    width:40%;
    text-align: center;
    padding: 5px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px;
}

.filter-area .filter-area-body .filter-inputs input[type="date"]{
    border-radius: 10px;
    border: none;
    padding: 5px;
    box-shadow: 0px 0px 3px 1px;
}

.filter-area .filter-area-footer{
    height: 10%;
    display: flex;
    align-items: center;
}

.filter-area .filter-area-footer button{
    margin-top: 15px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.pagina-extrato .fundo-lancamento .lancamento .MdOutlineWatchLater{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .fundo-lancamento .lancamento .relogio{
    font-size: 25px;
    color: #FFA0A0;
}

.pagina-extrato .fundo-lancamento .lancamento .col-xl-5 p{
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-color);
    padding: 7px 0px 0px 18px;
    margin: 0px;
}

.pagina-extrato .fundo-lancamento .lancamento .col-xl-5{
    width: 46.666667%
}

.pagina-extrato .fundo-lancamento .lancamento .col-xl-3{
    margin: 0px;
    padding: 0px;   
    width: 109px;
}

.pagina-extrato .fundo-lancamento .lancamento .col-xl-3 p{
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-color);
    text-align: center;
    padding: 11px 0px 0px 0px;
}

.pagina-extrato .fundo-lancamento .lancamento .BiChevronRight.col-xl-2 {
    padding: 7px 0px 0px 0px;
    width: 39px;
    text-align: center;
}

.pagina-extrato .fundo-lancamento .lancamento .seta-esquerda{
    font-size: 30px;
}

.pagina-extrato .fundo-lancamento .lancamento hr{
    width: 90%;
    margin-left: 35px;
    margin-top: 5px;
    margin-bottom: 0px;
    opacity: 0.6;
    color: #BABABA;
    height: 1px;
}

.pagina-extrato .lancamento .FaMoneyBillWave{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .lancamento .dinheiro{
    font-size: 25px;
    color: #9EE9B3;
}

.pagina-extrato .lancamento .RiHandbagLine{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .lancamento .compra{
    font-size: 25px;
    color: #FFA0A0;
}

.pagina-extrato .lancamento .FiShoppingCart{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .lancamento .mercado{
    font-size: 25px;
    color: #FFA0A0;
}

.pagina-extrato .lancamento .RiNetflixFill{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .lancamento .netflix{
    font-size: 25px;
    color: #FFA0A0;
}

.pagina-extrato .lancamento .FaGooglePlay{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.pagina-extrato .lancamento .google-play{
    font-size: 25px;
    color: #FFA0A0;
}

.pagina-extrato .fundo-lancamento .visualizar-lancamento{
    margin-top: 16px;
    border-radius: 13px;
    background: var(--bg-color);
}

.pagina-extrato a {
    color: black;
    text-decoration: none;
}

.pagina-extrato a:hover {
    color: black;
}

.extrato-item{
    cursor: pointer;

}
.entryMessage{
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--info-color);
    margin-top: 1em;
}

@media (min-width: 1400px){
    .container{
        max-width: 100% !important;
    }
}