:root {
    --primary-color: #0C1010;
    
    --secondary-color: #E30613;
    
    --auxiliary-color: #A51008;
    
    --white-off-color: #F8F8F8;
    
    --input-color: #EDEDED;
    
    --info-color: #9D9D9D;
    
    --bg-color: #F0F0F0;

    --bg-secondary-color: #F6F6F6;
    
    --dark-color: #3F3F3F;
    
    --text-primary: #0C1010;
    
    --text-gray: #4F4F4F;
    
    --text-secondary: #E30613;
    
    --text-auxiliary: #A51008;
    
    --text-white: #FFFFFF;
    
    --text-success: #27AE60;

}