*{
  margin: 0px;
  padding: 0px;
}
.deposito > .container{
  width: 1171px;
  margin-left: 348px;
  background: var(--bg-secondary-color);
  padding: 0px 54px 110px 28px;
} 

.deposito .container .titulo p{
  font-size: 16px ;
  font-weight: 700;
  color: var(--text-gray);
  padding: 23px 0px 0px 5px;
}


.deposito .forma-deposito{
  background: white;
  padding: 20px 24px 60px 42px;
  border-radius: 8px ;
}

.deposito .forma-deposito a{
  color: black;
  text-decoration: none;
}

.deposito .forma-deposito h1{
  color: var(--auxiliary-color);
  font-size: 20px;
  font-weight: 500;
}

.deposito .bloco{
  padding: 20px 0px 2px 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 92%;
  border-radius: 30px;
}

.deposito .bloco h2{
  font-size: 21px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0px;
  padding-top: 6px;
}

.deposito .bloco p{
  padding: 2px 0px 0px 0px;
  font-size: 13px;
  font-weight: 700;
  color: #908181;
}
