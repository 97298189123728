* {
    margin: 0px;
    padding: 0px;
}
.transferencia-ted{
    height: calc(100% - 170px);
}

.transferencia-ted>.container {
    width: auto;
    margin-left: 20%;
    height: 100%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
}


.transferencia-ted .botao .col-xl-8 {
    width: 75%;
}

.transferencia-ted .botao .col-xl-8 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.transferencia-ted button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.transferencia-ted button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.transferencia-ted .transferencia {
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px;
}

.transferencia-ted .transferencia .scroll {
    overflow-y: scroll;
    height: 540px;

}

.transferencia-ted .transferencia h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
    padding: 10px 0px 0px 0px;
}

.transferencia-ted .transferencia h2 {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
    padding: 26px 5px 0px 0px;
}

.transferencia-ted .transferencia p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin: 0px;
    padding: 26px 5px 7px 0px;
}

.transferencia-ted .transferencia input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.transferencia-ted .transferencia .seleciona {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.transferencia-ted .transferencia select {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}


.transferencia-ted .numero-conta .col-xl-6 {
    width: 42%;
}

.transferencia-ted .numero-conta input {
    width: 170px;
}

.transferencia-ted .numero-conta .conta.col-xl-6 input {
    width: 198px;
}

.transferencia-ted .transferencia .contato .contato1 {
    height: 65px;
    width: 65px;
    background: var(--info-color);
    border-radius: 100px;
    margin: 9px 0px 18px 18px;
    padding: 0px;
}

.transferencia-ted .contato {
    padding: 10px 0px 0px 0px;
}

.transferencia-ted .contato .contato1 .iniciais {
    padding: 0px 0px 14px 23px;
}

.transferencia-ted .contato .iniciais p {
    padding: 23px 0px 0px 0px;
}

.transferencia-ted .contato .nome p {
    padding: 11px 0px 0px 0px;
    text-align: center;
}

.transferencia-ted .resumo-transferencia {
    border-radius: 13px;
    min-height: 400px;
    background: var(--bg-color);
    padding: 23px 142px 10px 38px;
}

.transferencia-ted .resumo-transferencia h2 {
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-color);
    padding: 11px 5px 7px 0px;
}

.transferencia-ted .resumo-transferencia p {
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 0px;
}

.transferencia-ted .resumo-transferencia h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.transferencia-ted .resumo-transferencia .agencia .col-xl-6 {
    width: 94px;
}

.transferencia-ted .resumo-transferencia .conta.col-xl-6 {
    width: 153px;
}

.transferencia-ted .resumo-transferencia .agencia {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.transferencia-ted .resumo-transferencia h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 16px 0px 13px 0px
}

.transferencia-ted .botao-confirmar button {
    margin: 23px 0px 0px 40px;
    width: 424px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
    padding: 10px 10px 10px 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-height: 900px) {
    .transferencia-ted{
        height: 100%;
    }
}