*{
    margin: 0px;
    padding: 0px;
}

.ver-senha>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 63px 28px;
}

.ver-senha p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.ver-senha .senha {
    background: white;
    padding: 20px 24px 300px 42px;
    border-radius: 8px;
}

.ver-senha .senha  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
    text-align: center;
    padding-top: 15px;
}

.ver-senha .mostrar-senha{
    width: 236px;
    margin: auto;
    border-radius: 10px;
    background: #D9D9D9;
    margin-top: 15px;
}

.ver-senha .mostrar-senha .col-xl-6{
   width: 68%;
}

.ver-senha .mostrar-senha .icone.col-xl-6{
    width: 54px;
    padding: 24px 0px 0px 16px;
}

.ver-senha .mostrar-senha svg{
    font-size: 30px;
}

.ver-senha .mostrar-senha .col-xl-6 p{
    width: 185px;
    padding: 0px;
    margin: 9px;
    font-size: 41px;
    font-weight: 700;
    color: var(--auxiliary-color);
    text-align: center;
    letter-spacing: 15px;
}