* {
    margin: 0px;
    padding: 0px;
}

.novo-boleto-2>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 63px 28px;
}

.novo-boleto-2 .boleto button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.novo-boleto-2 .boleto .col-xl-6 {
    width: 79%;
}

.novo-boleto-2 .boleto .col-xl-2 {
    width: 106px;
}

.novo-boleto-2 .boleto .gera-boleto.col-xl-2 {
    width: 122px;
    padding: 0px;
}

.novo-boleto-2 .boleto button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.novo-boleto-2 .scroll {
    overflow-y: scroll;
    margin-top: 20px;
    height: 483px;
    width: 462px;
}

.novo-boleto-2 .forms {
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px;

}

.novo-boleto-2 .forms h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.novo-boleto-2 .forms h2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 21px 0px 14px 0px;
}

.novo-boleto-2 .forms p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin: 0px;
    padding: 13px 0px 0px 4px;
}


.novo-boleto-2 .forms input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.novo-boleto-2 .forms .seleciona {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.novo-boleto-2 .forms select {
    background: var(--input-color);
    border: none;
    width: 380px;
    color: var(--dark-color);
}

.novo-boleto-2 .forms .chave p {
    border: solid;
    padding: 10px 0px 2px 4px;
}

.novo-boleto-2 .forms .contato-frequentes p {
    color: var(--secondary-color);
    padding: 10px 0px 0px 4px;
    font-weight: 700;
}

.novo-boleto-2 .forms .contato {
    padding: 10px 0px 0px 0px;
}

.novo-boleto-2 .forms .contato .contato1 {
    height: 50px;
    width: 50px;
    background: var(--info-color);
    border-radius: 100px;
    margin: 9px 0px 18px 18px;
    padding: 0px;
}

.novo-boleto-2 .forms .contato .contato1 .iniciais {
    padding: 15px 0px 13px 13px;
}

.novo-boleto-2 .forms .contato .contato1 .nome p {
    border: solid;
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.dados {
    width: 433px;
    padding: 0px 0px 15px 0px;
}

.novo-boleto-2 .resumo-transferencia {
    border-radius: 13px;
    background: var(--bg-color);
    padding: 23px 142px 115px 38px;
}

.novo-boleto-2 .resumo-transferencia h2 {
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-color);
}

.novo-boleto-2 .resumo-transferencia p {
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 0px;
}

.novo-boleto-2 .resumo-transferencia h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.novo-boleto-2 .resumo-transferencia .agencia {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.novo-boleto-2 .resumo-transferencia h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 16px 0px 13px 0px
}

.novo-boleto-2 .forms .uf.col-xl-6{
    width: 28%;
}


.novo-boleto-2 .forms .uf.col-xl-6 input {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px 10px 17px;
    width: 110px;
    background: var(--input-color);
    border-radius: 21px;
}

.novo-boleto-2 .forms .cidade.col-xl-6 input {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px 10px 17px;
    width: 289px;
    background: var(--input-color);
    border-radius: 21px;
}

.novo-boleto-2 .forms  .descricao {
    padding: 10px 0px 53px 17px;
}

.novo-boleto-2 .botao-confirmar button {
    margin: 23px 0px 0px 15px;
    width: 480px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
    padding: 10px 10px 10px 10px;
}

a {
    text-decoration: none;
}