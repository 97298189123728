*{
  margin: 0px;
  padding: 0px;
}

.bloquear-cartao > .container{
  width: 1171px;
  margin-left: 348px;
  background: var(--bg-secondary-color);
  padding: 0px 54px 50px 28px;
} 

.bloquear-cartao  p {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-gray);
  padding: 23px 0px 0px 5px;
}

.bloquear-cartao .fundo {
  background: white;
  padding: 20px 24px 37px 42px;
  border-radius: 8px;
}

.bloquear-cartao .fundo h1 {
  color: var(--auxiliary-color);
  font-size: 20px;
  font-weight: 500;
}

.bloquear-cartao .img-card{
  padding: 15px 0px 0px 6px;
}

.bloquear-cartao .bloquear img{
  width: 334px;
}

.bloquear-cartao .bloquear .inf{
  width: 232px;
}

.bloquear-cartao .bloquear .inf h2{
  font-size: 14px;
  font-weight: bold;
  color: var(--dark-color);
  padding: 18px 0px 8px 0px;
  margin: 0px;
}

.bloquear-cartao .bloquear .inf p{
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-color);
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.bloquear-cartao .botao-confirmar-red button{
  margin: 32px 0px 0px 0px;
  width: 160px;
  border: none;
  background: var(--secondary-color);
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius:22px ;
  padding: 10px 10px 10px 10px;
}

.bloquear-cartao .botao-confirmar-black button{
  margin: 33px 0px 40px 0px;
  width: 160px;
  border: none;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius:22px ;
  padding: 10px 10px 10px 10px;
}








