*{
    margin: 0px;
    padding: 0px;
}
.pagar-boleto{
    height: calc(100% - 170px);
}

.pagar-boleto > .container{
    width: auto;
    margin-left: 20%;
    height: 100%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
}

.pagar-boleto .boleto .col-xl-8 p{
    padding: 23px 0px 0px 5px;
}

.pagar-boleto .boleto button:hover { 
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.pagar-boleto .boleto .col-xl-6 {
    width: 69%;
}
  
.pagar-boleto .boleto .col-xl-2{
    width: 106px;
}
  
.pagar-boleto .boleto .gera-boleto.col-xl-2{
    width: 122px;
    padding: 0px;
}
  
.pagar-boleto .boleto button{
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.pagar-boleto .transferencia input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}


.pagar-boleto .transferencia{
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px ;
}

.pagar-boleto .transferencia h1{
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.pagar-boleto .transferencia h2{
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 21px 0px 14px 0px;
}

.pagar-boleto .transferencia p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin: 0px;
    padding: 0px 0px 2px 4px;
}
.pagar-boleto .botao-confirmar button{
    margin: 23px 0px 0px 0px;
    width: 424px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius:22px ;
    padding: 10px 10px 10px 10px;
}

.pagar-boleto .resumo-transferencia{
    border-radius: 13px;
    background: var(--bg-color);
    padding: 23px 142px 90px 38px;
}

.pagar-boleto .resumo-transferencia h2{
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-color);
}

.pagar-boleto .resumo-transferencia p{
    font-size: 14px;
    font-weight: 400;
    color:var(--dark-color) ;
    padding: 0px;
}

.pagar-boleto .resumo-transferencia h4{
    font-size: 20px;
    font-weight: 500;
    color:var(--dark-color) ;
}

.title{
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
}








