*{
    padding: 0px;
    margin: 0px;
}

.minhas-chaves-pix > .container{
    width: auto;
    margin-left: 20%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 170px  28px;
}

.minhas-chaves-pix .titulo .col-xl-8 {
    width: 66%;
}

.minhas-chaves-pix .titulo .col-xl-8 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.minhas-chaves-pix .titulo button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.minhas-chaves-pix .titulo .pagar.col-xl-2 {
    width: 100px;
}

.minhas-chaves-pix .titulo .gerar-codigo.col-xl-2 {
    width: 146px;
}

.minhas-chaves-pix .titulo button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.minhas-chaves-pix .chaves-pix {
    background: white;
    padding: 20px 24px 76px 42px;
    border-radius: 8px;
    min-height: 500px;
}

.minhas-chaves-pix .chaves-pix h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.minhas-chaves-pix .registra-chave .col-xl-6{
    width: 69%;
}

.minhas-chaves-pix .registra-chave .col-xl-4{
    width: 22%;
}

.minhas-chaves-pix .registra-chave .col-xl-2{
    width: 8%;
}

.minhas-chaves-pix .registra-chave h2{
    font-size: 20px;
    font-weight: 500;
    padding: 3px 0px 0px 0px;
    color: var(--primary-color);
}


.minhas-chaves-pix .registra-chave .col-xl-2 svg{
    font-size: 23px;
}

.minhas-chaves-pix .registra-chave p{
    font-size: 15px;
    font-weight: 500;
    padding: 3px 0px 0px 0px;
    color: var(--primary-color);
}

.minhas-chaves-pix .chaves{
    padding: 20px 0px 6px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0px;
    width: 50%;
    border-radius: 30px;
}

.minhas-chaves-pix .chave-cadastrada p{   
    font-size: 14px;
    color: var(--auxiliary-color);
    font-weight: bold;
    margin: 22px 0px 10px 0px;
}

.minhas-chaves-pix .chaves svg{
    font-size: 35px;
    margin: 5px 0px 10px 0px;
      color: var(--auxiliary-color);   
}

.minhas-chaves-pix .chaves .col-xl-2{
    width: 14%;
}

.minhas-chaves-pix .chaves h3{
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-color);
}

.minhas-chaves-pix .chaves .lixeira svg{
    font-size: 30px;
    color: var(--auxiliary-color);
}

.minhas-chaves-pix a{
    text-decoration: none;
    color: black;
}