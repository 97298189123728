.desc-extrato{
    min-height: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.desc-extrato h2{
    text-align: center;
    font-size: 23px;
    font-weight: 500;
}
.desc-info{
    height: 100%;
}

.desc-header{
    background-color: var(--white-off-color);
    padding: 20px;
    color: var(--text-primary);
    display: flex;
    gap: 1em;
}

.desc-header img {
    width: 70px;
}

.desc-body{
    background-color: var(--white-off-color);
    display: flex;
    flex-direction: column;
}

.desc-body .value-area {
    color: #353535 !important;
}

.desc-body .value-area strong {
    color: #000 !important;
    font-size: 18px;
}

.desc-body label{
    margin-left: 1em;
    margin-bottom: -10px;
}

.desc-body h1 {
    padding: 0 0 0 10px !important;
    margin-bottom: -10px;
    font-size: 14px !important;
}

.desc-footer h1 {
    padding: 0 0 0 10px !important;
    margin-bottom: -10px;
    font-size: 14px !important;
}

.desc-body p {
    margin: 0.3em;
    color: var(--text-primary) !important;
}

.desc-footer p {
    margin: 0.3em;
    color: var(--text-primary) !important;
}



.desc-footer{
    height: 50%;
    background: var(--white-off-color);
    padding-bottom: 1em;
}

.desc-footer label{
    margin-left: 1em;
}

.print-area{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 1em;
}

.print-area button {
    margin-top: 15px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.print-area svg{
    margin-right: 5px;
}