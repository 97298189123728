*{
    padding: 0px;
    margin: 0px;
}

.create-pix-key > .container{
    width: auto;
    margin-left: 20%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 137px  28px;
}

.create-pix-key .titulo .col-xl-8 {
    width: 66%;
}

.create-pix-key .titulo .col-xl-8 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.create-pix-key .titulo button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.create-pix-key .titulo .pagar.col-xl-2 {
    width: 100px;
}

.create-pix-key .titulo .gerar-codigo.col-xl-2 {
    width: 146px;
}

.create-pix-key .titulo button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.create-pix-key .codigo-token {
    background: white;
    padding: 20px 24px 300px 42px;
    border-radius: 8px;
}

.create-pix-key .codigo-token  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
    text-align: center;
    padding-top: 25px;
}

.create-pix-key .campo-token { 
    margin: auto;
    width: 267px;
    padding: 18px 0px 0px 0px;    
}

.create-pix-key .campo-token p{
    padding: 0px;
}

.create-pix-key .codigo-token .col-xl-2{
    height: 40px;
    width: 34px;
    margin: 9px 1px 11px 6px;
    background: #D9D9D9;
    border-radius: 10px;
    padding: 0px;
}

.create-pix-key .codigo-token .col-xl-1{
    height: 2px;
    border: solid;
    margin: 26px 4px 0px 12px;
}

.create-pix-key .botoes .confirmar.col-xl-6 {
    width: 42%;
    margin: auto;
}

.create-pix-key .campo-token input{
    width: 33px;
    margin: 0px 0px 0px 0px;
    background: #D9D9D9;
    border: none;
    text-decoration: none;
    padding: 0px 0px 12px 10px;
    border-radius: 10px;
}

.create-pix-key .campo-token a{
    text-decoration: none;
    color: black;
}