* {
    margin: 0px;
    padding: 0px;
}

.novo-boleto>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 100px 28px;
}

.novo-boleto a {
    text-decoration: none;
    color: black;
}

.novo-boleto a:hover {
    text-decoration: none;
    color: black;
}

.novo-boleto .boleto .col-xl-6 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.novo-boleto .boleto button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}
.novo-boleto .boleto button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.novo-boleto .historico-boleto {
    background: white;
    padding: 20px 24px 272px 42px;
    border-radius: 8px;
}

.novo-boleto .historico-boleto h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.novo-boleto .historico-boleto h2 {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 6px 0px 14px 0px;
}

.novo-boleto .descricao-boleto {
    border: solid;
    border-radius: 16px;
    background: white;
    border: none;
}

.head-boleto {
    text-align: center;
    padding: auto;
}

.headTable p {
    text-align: center;
    padding: 20px 0px 0px 0px;
    font-weight: 600;
}

.novo-boleto .descricao-boleto .icon {
    font-size: 36px;
    padding: 0px 0px 0px 30px;
    color: #464643;
}

.novo-boleto .descricao-boleto .descricao {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}

.novo-boleto .descricao-boleto .datas {
    padding: 18px 0px 0px 0px;
    text-align: center;
}

.novo-boleto .descricao-boleto .valores {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto .icon-end {
    font-size: 22px;
    padding: 5px 0px 0px 25px;


}

.novo-boleto .descricao-boleto-2 .icon-2 {
    font-size: 36px;
    padding: 0px 0px 0px 30px;
    color: #464643;
}

.novo-boleto .descricao-boleto-2 {
    border: solid;
    border-radius: 16px;
    background: var(--input-color);
    border: none;


}

.novo-boleto .descricao-2 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-2 p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}

.novo-boleto .descricao-boleto-2 .datas-2 {
    padding: 8px 0px 0px 0px;
    text-align: center;
}

.novo-boleto .descricao-boleto-2 .valores-2 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-2 .icon-end {
    font-size: 22px;
    padding: 6px 0px 0px 24px;


}

.novo-boleto .descricao-boleto-2 .icon-2 {
    font-size: 36px;
    padding: 0px 0px 0px 30px;
    color: #464643;
}

.novo-boleto .descricao-boleto-2 {
    border: solid;
    border-radius: 16px;
    background: var(--bg-secondary-color);
    border: none;


}

.novo-boleto .descricao-boleto-3 .icon-3 {
    font-size: 36px;
    padding: 0px 0px 0px 30px;
    color: #464643;
}

.novo-boleto .descricao-boleto-3 {
    border: solid;
    border-radius: 16px;
    background: #FFF;
    border: none;

}

.novo-boleto .descricao-3 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-3 p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}

.novo-boleto .descricao-boleto-3 .datas-3 {
    padding: 8px 0px 0px 0px;
    text-align: center;
}

.novo-boleto .descricao-boleto-3 .valores-3 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-3 .icon-end {
    font-size: 22px;
    padding: 6px 0px 0px 24px;


}

.novo-boleto .descricao-boleto-4 .icon-4 {
    font-size: 36px;
    padding: 0px 0px 0px 30px;
    color: #464643;
}

.novo-boleto .descricao-boleto-4 {
    border: solid;
    border-radius: 16px;
    background: var(--bg-secondary-color);
    border: none;

}

.novo-boleto .descricao-4 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-4 p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}

.novo-boleto .descricao-boleto-4 .datas-4 {
    padding: 20px 0px 0px 0px;
    text-align: center;
}

.novo-boleto .descricao-boleto-4 .valores-4 {
    text-align: center;
    padding: 20px 0px 0px 0px;
}

.novo-boleto .descricao-boleto-4 .icon-end {
    font-size: 22px;
    padding: 6px 0px 0px 24px;

}
.title{
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
}