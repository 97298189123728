*{
    margin: 0px;
    padding: 0px;
}

.componente-acesso .acesso-rapido h1{
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-color);
    padding: 28px 0px 0px 15px;
}

.componente-acesso .FaWallet img{
    width: 30px;
    margin: 0px;
}

.componente-acesso .scroll{
    overflow-y: auto;
    height: 90%;
}

.componente-acesso .tipo-acesso{
    margin: auto;
    width: 90%;
    background: white ;
    border-radius: 20px;
    margin-top: 13px;
}

.componente-acesso .tipo-acesso .col-xl-6{
    width: 85px;
    margin-left: 4px;
}

.componente-acesso .tipo-acesso .FaWallet{
    text-align: center;
    font-size: 30px;
    color: var(--secondary-color);
}

.componente-acesso .tipo-acesso .col-xl-6 p{
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 16px 0px 0px 0px ;
}

.componente-acesso a{
    text-decoration: none;
}