* {
    margin: 0px;
    padding: 0px;
}

.header {
    width: 80%;
    position: fixed;
    background: white;
    z-index: 1;
    margin-left: 20%;
}


.header>.container {
    width: 100%;
}

.header .cabecalho {
    padding: 13px 10px 16px 0px;
}

.header .col-xl-8 {
    text-align: right;
}

.header .MdOutlineNotificationsNone {
    font-size: 30px;
    margin: 0px;
    padding: 0px
}

.header .perfil.col-xl-1 {
    width: 62px;
    padding: 7px 0px 0px 25px;
}

.header .agencia.col-xl-1 {
    padding: 0px;
    width: 55px;
}

.header .agencia p {
    font-size: 11px;
    font-weight: 500;
    color: var(--primary-color);
    opacity: 0.5;
    padding: 6px 0px 0px 6px;
    margin: 0px;
}

.header .agencia .codigo {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    opacity: 1;
    padding: 0px 0px 0px 7px;
    margin: 0px;
}

.header .linha.col-xl-1 {
    width: 10px;
}

.header .linha div {
    border: 1px solid black;
    background: black;
    height: 37px;
    width: 0px;
    margin: 7px 0px 0px 0px;
}

.header .conta p {
    font-size: 11px;
    font-weight: 500;
    color: var(--primary-color);
    opacity: 0.5;
    padding: 6px 0px 0px 0px;
    margin: 0px;
}

.header .conta .codigo {
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-color);
    opacity: 1;
    padding: 0px 0px 0px 0px;
    margin: 0px;

}

.header .FaChevronDown.col-xl-1 {
    width: 0px;
    text-align: center;
    padding: 20px 0px 0px 10px;
}

.header .logout.col-xl-1 {
    width: 0px;
    text-align: center;
    padding: 14px 61px 0px 0px;
}

.header .row {
    display: flex;
    justify-content: end;
}

.header .conta {
    margin-right: 0px;
}
