* {
  margin: 0px;
  padding: 0px;
}

.tranferencia-boleto>.container {
  width: 1171px;
  margin-left: 348px;
  background: var(--bg-secondary-color);
  padding: 0px 54px 63px 28px;
}

.tranferencia-boleto .boleto a {
  text-decoration: none;
  color: black;
}

.tranferencia-boleto a:hover {
  text-decoration: none;
  color: black;
}

.tranferencia-boleto .boleto .col-xl-6 p {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-gray);
  padding: 23px 0px 0px 5px;
}

.tranferencia-boleto .boleto button:hover {
  background: var(--secondary-color);
  transition: 0.5s;
  border-radius: 4px;
}

.tranferencia-boleto .boleto .gera-boleto.col-xl-2{
  width: 118px;
  padding: 0px;
}

.tranferencia-boleto .boleto .col-xl-6 {
  width: 79%;
}

.tranferencia-boleto .boleto .col-xl-2{
  width: 106px;
}

.tranferencia-boleto .boleto .gera-boleto.col-xl-2{
  width: 122px
}

.tranferencia-boleto .boleto button{
  margin-top: 15px;
  border: none;
  background: var(--info-color);
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 17px 6px 17px;
  border-radius: 24px;
}

.tranferencia-boleto .transferencia {
  background: white;
  padding: 20px 24px 26px 42px;
  border-radius: 8px;
}

.tranferencia-boleto .transferencia h1 {
  font-size: 20px;
  font-weight: 700;
  color: var(--auxiliary-color);
}

.tranferencia-boleto .transferencia h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
  padding: 28px 0px 8px 0px;
}

.tranferencia-boleto .transferencia p {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0px;
  padding: 0px 0px 0px 4px; 
}

.tranferencia-boleto .multa .multa.col-xl-6 input {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-color);
  padding: 10px 0px 10px 17px;
  width: 160px;
  background: var(--input-color);
  border-radius: 21px;

}

.tranferencia-boleto .multa .juros.col-xl-6 input {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-color);
  padding: 10px 0px 10px 17px;
  width: 160px;
  background: var(--input-color);
  border-radius: 21px;

}

.tranferencia-boleto .transferencia input {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-color);
  padding: 10px 28px 10px 17px;
  width: 419px;
  background: var(--input-color);
  border-radius: 21px;
  border: none;
}


.tranferencia-boleto .botao-confirmar button {
  margin: 34px 0px 0px 0px;
  width: 424px;
  border: none;
  background: var(--secondary-color);
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 22px;
  padding: 10px 10px 10px 10px;
}

.tranferencia-boleto .transferencia .resumo-boleto.col-xl-6{
  margin-top: 16px;
  border-radius: 13px;
  background: var(--bg-color);
  padding: 0px 0px 206px 0px;
}

