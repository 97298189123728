*{
    margin: 0px;
    padding: 0px;
}

.cartao-de-credito > .container{
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 6px 28px;
}

.cartao-de-credito p{
    font-size: 16px ;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.cartao-de-credito .fatura-mes{
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px ;
}

.cartao-de-credito .fatura-mes h1{
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
}

.cartao-de-credito .fatura-mes p{
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-color);
    padding: 28px 0px 0px 0px;
    margin: 0px;
}

.cartao-de-credito .fatura-mes .valor{
    font-size: 32px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 0px;
    margin: 0px;
}

.cartao-de-credito .fatura-mes .pagamento{
    font-size: 16px;
    font-weight: 400;
    color: --text-success;
    padding: 0px;
    margin: 0px;
}

.cartao-de-credito .fatura-mes h2{
    padding: 24px 0px 0px 0px;
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
}

.cartao-de-credito .scroll p{
    font-size: 14px;
    font-weight: 700;
    color: var(--info-color);
    padding: 10px 0px 0px 0px;
}

.cartao-de-credito .fatura-mes .lancamento{
    border-radius: 16px ;
    width: 445px;
    padding: 9px 0px 16px 0px;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
    margin-top: 15px;
}

.cartao-de-credito .fatura-mes .lancamento .MdOutlineWatchLater{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .relogio{
    font-size: 25px;
    color: #FFA0A0;
}

.cartao-de-credito .fatura-mes .lancamento .col-xl-5 p{
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-color);
    margin: 0px;
    padding: 12px 0px 0px 0px;
}

.cartao-de-credito .fatura-mes .lancamento .col-xl-3{
    margin: 0px;
    padding: 0px;  
    width: 147px;
}

.cartao-de-credito .fatura-mes .lancamento .col-xl-3 p{
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary-color);
    text-align: center;
    padding: 11px 0px 0px 28px;
}

.cartao-de-credito .fatura-mes .lancamento .BiChevronRight.col-xl-2 {
    padding: 7px 0px 0px 0px;
    width: 39px;
    text-align: center;
}

.cartao-de-credito .fatura-mes .lancamento .seta-esquerda{
    font-size: 30px;
}

.cartao-de-credito .fatura-mes .lancamento hr{
    width: 399px;
    margin-left: 35px;
    margin-top: 5px;
    opacity: 0.6;
    color: #BABABA;
    height: 1px;
}

.cartao-de-credito .fatura-mes .scroll{
    overflow-y: scroll;
    margin-top: 20px;
    height: 568px;
}

.cartao-de-credito .fatura-mes .lancamento .FaMoneyBillWave{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .dinheiro{
    font-size: 25px;
    color: #9EE9B3;
}

.cartao-de-credito .fatura-mes .lancamento .RiHandbagLine{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .compra{
    font-size: 25px;
    color: #FFA0A0;
}

.cartao-de-credito .fatura-mes .lancamento .FiShoppingCart{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .mercado{
    font-size: 25px;
    color: #FFA0A0;
}

.cartao-de-credito .fatura-mes .lancamento .RiNetflixFill{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .netflix{
    font-size: 25px;
    color: #FFA0A0;
}

.cartao-de-credito .fatura-mes .lancamento .FaGooglePlay{
    padding: 9px 0px 0px 32px;
    width: 64px;
}

.cartao-de-credito .fatura-mes .lancamento .google-play{
    font-size: 25px;
    color: #FFA0A0;
}

.cartao-de-credito .fatura-mes .fundo-lancamento .visualizar-lancamento{
    margin-top: 16px;
    border-radius: 13px;
    background: var(--bg-color);
    padding: 0px 0px 206px 0px;
}

.cartao-de-credito .fatura-mes .fundo-lancamento .icone-documento{
    padding-top:150px ;
    font-size: 100px;
    text-align: center;
}

.cartao-de-credito .fatura-mes .fundo-lancamento .icone-documento p{
    color: var(--secondary-color);
    font-size: 18px;
    text-align: center;
    margin: auto;
    width: 271px;
}

.cartao-de-credito .fatura-mes .botoes { 
    padding: 17px 0px 0px 10px;
}

.cartao-de-credito .fatura-mes .botoes .pagar.col-xl-6 { 
    width: 235px;
}

.cartao-de-credito .fatura-mes .botoes .pagar.col-xl-6 button{
    background: var(--secondary-color);
    color: white;
    padding: 5px 90px 7px 90px;
    border-radius: 22px;
    border: none;
}

.cartao-de-credito .fatura-mes button{
    background: var(--primary-color);
    color: white;
    padding: 5px 70px 7px 70px;
    border-radius: 22px;
    border: none;
}



