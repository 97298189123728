* {
    margin: 0px;
    padding: 0px;
}

.pagamento-realizado>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 46px 28px;
}

.pagamento-realizado .titulo p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}


.pagamento-realizado .comprovante-pagamento {
    background: white;
    padding: 20px 24px 66px 42px;
    border-radius: 8px;
}

.pagamento-realizado .comprovante-pagamento a {
    color: black;
    text-decoration: none;
}

.pagamento-realizado .comprovante-pagamento h1 {
    color: var(--auxiliary-color);
    font-size: 20px;
    font-weight: bold;
    padding: 0px 19px 0px 16px;
}

.pagamento-realizado .comprovante-pagamento .centraliza {
    width: 36%;
    margin: auto;
}

.pagamento-realizado .comprovante-pagamento img {
    width: 210px;
}

.pagamento-realizado .comprovante-pagamento .imagem {
    width: 68%;
    margin: auto;
    padding: 20px 0px 40px 0px;
}

.pagamento-realizado .comprovante-pagamento strong {
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.pagamento-realizado .comprovante-pagamento .vencimento {
    padding: 37px 0px 7px 15px;
}

.pagamento-realizado .comprovante-pagamento .vencimento strong {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
}

.pagamento-realizado .comprovante-pagamento .vencimento p {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
    margin: 0px;
}

.pagamento-realizado .comprovante-pagamento .limite {
    padding: 0px 0px 7px 15px;
}

.pagamento-realizado .comprovante-pagamento .limite strong {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
}

.pagamento-realizado .comprovante-pagamento .limite p {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
    margin: 0px;
}

.pagamento-realizado .comprovante-pagamento .pagamento{
    padding: 0px 0px 7px 15px;
}


.pagamento-realizado .comprovante-pagamento .pagamento strong {
    color: --text-success;
    font-size: 19px;
    font-weight: bold;
}

.pagamento-realizado .comprovante-pagamento .pagamento p {
    font-size: 20px;
    font-weight: 600;
    color: --text-success;
}

.pagamento-realizado .botoes .email button {
    background: var(--secondary-color);
    color: white;
    padding: 5px 19px 7px 19px;
    border-radius: 22px;
    border: none;
}

.pagamento-realizado .botoes {
    padding: 17px 0px 0px 0px;
}

.pagamento-realizado .botoes .pdf button {
    background: var(--primary-color);
    color: white;
    padding: 5px 32px 7px 32px;
    border-radius: 22px;
    border: none;
}