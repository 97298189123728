* {
    margin: 0px;
    padding: 0px;
}
.transferencia-pix{
    height: calc(100% - 170px);
}

.transferencia-pix>.container {
    width: auto;
    margin-left: 20%;
    height: 100%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 63px 28px;
}

.transferencia-pix .pix .col-xl-8 {
    width: 66%;
}

.transferencia-pix .pix .col-xl-8 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.transferencia-pix .pix button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.transferencia-pix .pix .pagar.col-xl-2 {
    width: 100px;
}

.transferencia-pix .pix .gerar-codigo.col-xl-2 {
    width: 146px;
}

.transferencia-pix .pix button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.transferencia-pix .transferencia {
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px;
}

.transferencia-pix .transferencia h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.transferencia-pix .transferencia h2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 21px 0px 14px 0px;
}

.transferencia-pix .transferencia p {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    margin: 0px;
    padding: 0px 0px 2px 4px;
}

.transferencia-pix .transferencia .cpf{
    padding: 0px 0px 8px 0px;
}

.transferencia-pix .transferencia input, textarea {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.transferencia-pix .transferencia .description textarea{
    height: 85px;
    max-height: 100px;
    min-height: 85px;
}

.transferencia-pix .transferencia .seleciona {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.transferencia-pix .transferencia select {
    background: var(--input-color);
    border: none;
    width: 380px;
    color: var(--dark-color);
}

.transferencia-pix .transferencia .chave p {
    padding: 10px 0px 2px 4px;
}

.transferencia-pix .transferencia .contato-frequentes p {
    color: var(--secondary-color);
    padding: 10px 0px 0px 4px;
    font-weight: 700;
}

.transferencia-pix .transferencia .contato {
    padding: 10px 0px 0px 0px;
}

.transferencia-pix .transferencia .contato .contato1 {
    height: 50px;
    width: 50px;
    background: var(--info-color);
    border-radius: 100px;
    margin: 9px 0px 18px 18px;
    padding: 0px;
}

.transferencia-pix .transferencia .contato .contato1 .iniciais {
    padding: 15px 0px 13px 13px;
}

.transferencia-pix .transferencia .contato .contato1 .nome p {
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.transferencia-pix .resumo-transferencia {
    height: 550px;
    border-radius: 13px;
    background: var(--bg-color);
    padding: 23px 142px 25px 38px;
}

.transferencia-pix .resumo-transferencia h2 {
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-color);
}

.transferencia-pix .resumo-transferencia p {
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 0px;
}

.transferencia-pix .resumo-transferencia h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.transferencia-pix .resumo-transferencia .agencia {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-color);
}

.transferencia-pix .resumo-transferencia h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 16px 0px 13px 0px
}

.transferencia-pix .botao-confirmar button {
    margin: 23px 0px 0px 40px;
    width: 424px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
    padding: 10px 10px 10px 10px;
}

.password-modal{
    margin: auto;
    min-width: 300px;
    min-height: 200px;
    background-color: white;
    box-shadow: 0px 0px 5px 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
}

.password-modal label {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
}

.password-modal input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 90%;
    background: var(--input-color);
    border-radius: 21px;
}

.overlay-password-modal{
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: rgb(0, 0,0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
}

.modal-close-button{
    background-color: transparent;
    border: none;
    position: absolute;
    top: -30px;
    right: -30px;
}

.modal-confirm-button{
    width: 70%;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
    padding: 10px 10px 10px 10px;
}


@media screen and (max-height: 900px) {
    .transferencia-pix{
        height: 100%;
    }
}