* {
    margin: 0px;
    padding: 0px;
}

.senha-pagamento-fatura>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
}

.senha-pagamento-fatura .titulo p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.senha-pagamento-fatura .fundo-senha {
    background: white;
    padding: 20px 24px 37px 42px;
    border-radius: 8px;
    height: 752px;
}

.senha-pagamento-fatura .fundo-senha a {
    color: black;
    text-decoration: none;
}

.senha-pagamento-fatura .fundo-senha h1 {
    color: var(--auxiliary-color);
    font-size: 20px;
    font-weight: 500;
}

.senha-pagamento-fatura .fundo-senha p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 21px 0px 4px 0px;
    margin: 0px;
}

.senha-pagamento-fatura .fundo-senha input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.senha-pagamento-fatura .fundo-senha button{
    background: var(--secondary-color);
    color: white;
    padding: 5px 67px 7px 67px;
    border-radius: 22px;
    border: none;
    margin: 23px 0px 0px 214px;
}