* {
    padding: 0px;
    margin: 0px;
}
.cadastro-chave-pix{
    height: calc(100% - 170px);
}

.cadastro-chave-pix>.container {
    width: auto;
    margin-left: 20%;
    height: 100%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
}


.cadastro-chave-pix .titulo .col-xl-8 {
    width: 66%;
}

.cadastro-chave-pix .titulo .col-xl-8 p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}

.cadastro-chave-pix .titulo button:hover {
    background: var(--secondary-color);
    transition: 0.5s;
    border-radius: 4px;
}

.cadastro-chave-pix .titulo .pagar.col-xl-2 {
    width: 100px;
}

.cadastro-chave-pix .titulo .gerar-codigo.col-xl-2 {
    width: 146px;
}

.cadastro-chave-pix .titulo button {
    margin-top: 15px;
    border: none;
    background: var(--info-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 17px 6px 17px;
    border-radius: 24px;
}

.cadastro-chave-pix .cadastro-chave {
    background: white;
    padding: 20px 24px 26px 42px;
    border-radius: 8px;
}

.cadastro-chave-pix .cadastro-chave h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
}

.cadastro-chave-pix .cadastro-chave h2 {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 21px 0px 14px 0px;
}

.cadastro-chave-pix .cadastro-chave h3{
    font-size: 15px;
    font-weight: 500;
    color: var(--primary-color);
    padding: 21px 0px 0px 0px;
}

.cadastro-chave-pix .cadastro-chave .seleciona {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.cadastro-chave-pix .cadastro-chave select {
    background: var(--input-color);
    border: none;
    width: 380px;
    color: var(--dark-color);
}

.cadastro-chave-pix .cadastro-chave input {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    padding: 10px 0px 10px 17px;
    width: 419px;
    background: var(--input-color);
    border-radius: 21px;
}

.cadastro-chave-pix .cadastro-chave button {
    margin-top: 30px;
    border: none;
    background: var(--secondary-color);
    color: white;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 189px 8px 171px;
    border-radius: 24px;
}

