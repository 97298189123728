*{
  margin: 0px;
  padding: 0px;
}

.forms > .container{
  width: 1171px;
  margin-left: 348px;
  background: var(--bg-secondary-color);
  padding: 20px 54px 63px 28px;
}

.forms .container p {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-gray);
  
}


.forms .limit {
  background: white;
  padding: 20px 0px 0px 20px;
  border-radius: 8px ;
  height: 838px;

}

.forms .limit h1{
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
}

.forms .limit .limit-container{
  margin-left: 300px;
  margin-top: 100px;
}
.forms .limit .value p {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0px 0px 0px 50px;
  
}

.forms .limit p {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0px;
  padding: 10px 0px 8px 4px;
}


.forms .limit input {
  font-size: 14px;
  font-weight: 400;
  color: var(--dark-color);
  padding: 10px 28px 10px 17px;
  width: 419px;
  background: var(--input-color);
  border-radius: 21px;
  border: none;
}


.forms .botao-ajustar button{
  margin: 26px 0px 0px 0px;
  width: 424px;
  border: none;
  background: var(--secondary-color);
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius:22px ;
  padding: 10px 10px 10px 10px;
}
.forms .limit .icon {
  margin: 400px 0 0 200px;
}
.forms .limit .icon p{
  margin-left: -30px;
}








