*{
    margin: 0;
    padding: 0;
}

.menu {
    background: var(--bg-color);
    width: 20%;
    color: white;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: 100%;
}

.menu  a{
    color: black;
    text-decoration: none;
}


.menu .container{
    max-width: 100%;
}

.menu img {
    width: 80%;
    margin: auto;
}

.menu .saldo{
    border: none;
    margin: auto;
    width: 100%;
    margin-top: 33px;
    border-radius: 20px;
    background: var(--secondary-color);
    color: white;
    padding: 3px 0px 10px 0px;
}

.menu .col-xl-6{
    width: 45%;
    padding: 0px;
}

.menu .saldo .col-xl-6 p{
    margin: 7px 0px 0px 34px;
    font-size: 12px;
    font-weight: 400;
}

.menu #dinheiro {
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0px 0px 33px;
}

.menu .col-xl-3.BsEyeSlash{
    padding: 20px 0px 0px 0px;
    font-size: 20px;
}

.menu .col-xl-3.FiChevronRight{
    width:30%;
    text-align: right;
    font-size: 32px;
}

.meus-cartoes h1{
    font-size: 10px;
    font-weight: 700;
    color: var(--secondary-color);
    padding: 28px 0px 0px 15px;
}

.menu .cartao{
    margin: auto;
    margin-top: 10px;
    width: 100%;
    background: var(--dark-color);
    border-radius: 20px;
    padding: 23px 17px 5px 14px;
}

.menu .cartao .col-xl-5 img{
    width: 100px;
    margin: 0px;
    margin-top: 6px;
}

.menu .cartao .col-xl-6 {
    width: 55%;
    font-size: 16px;
    font-weight: 500;
    color: white;
}

