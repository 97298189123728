*{
    margin: 0px;
    padding: 0px;
}
.boas-vindas{
    margin-left: 20%;
    width: auto;
}

.boas-vindas > .container{
    background: var(--bg-secondary-color);
    padding: 75px 54px 6px 28px
} 

.boas-vindas h1{
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
    padding: 35px 0px 0px 5px;
}

.boas-vindas h2{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    opacity: 0.6;
    padding: 0px 0px 0px 5px;
}

.boas-vindas .col-xl-5 input{
    position: absolute;
    border: none;
    width: 440px;
    padding: 9px 0px 9px 15px;
    margin-top: 30px;
    border-radius: 4px;
    color: #54708C;
    font-size: 14px;
    font-weight: 500;
}

 .boas-vindas .col-xl-5 .lupa{
    position: absolute;
    margin: 39px 0px 0px 411px;
    font-size: 20px;
}