*{
    margin: 0px;
    padding: 0px;
}

.fundo-selecionar .icone-documento{
    padding-top:150px ;
    font-size: 100px;
    text-align: center;

}

.fundo-selecionar p{
   text-align: center;
   color:var(--secondary-color) !important ;
   font-size: 18px;
   font-weight: 500;
   padding: 0px 0px 250px 0px !important;
}