*{
    margin: 0px;
    padding: 0px;
}

.senha>.container {
    width: 1171px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 63px 28px;
}

.senha p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}


.senha .codigo-senha {
    background: white;
    padding: 20px 24px 300px 42px;
    border-radius: 8px;
}

.senha .codigo-senha  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--auxiliary-color);
    text-align: center;
    padding-top: 25px;
}

.senha .campo-senha{ 
    margin: auto;
    width: 330px;
    padding: 50px 0px 0px 0px;    
}

.senha .codigo-senha .col-xl-2{
    height: 40px;
    width: 34px;
    margin: 9px 1px 11px 6px;
    background: #D9D9D9;
    border-radius: 10px;
    padding: 0px;
}

.senha .codigo-senha .col-xl-1{
    height: 2px;
    border: solid;
    margin: 26px 4px 0px 12px;
}

.senha .botoes{
    margin: auto;
    width: 304px;
    padding: 14px 0px 0px 0px;
}

.senha .botoes .reenviar.col-xl-6 {
    width: 48%;
    padding: 0px;
}

.senha .botoes .confirmar.col-xl-6 {
    width: 42%;
    margin: auto;
}

.senha .campo-senha input{
    width: 33px;
    margin: 0px 0px 0px 0px;
    background: #D9D9D9;
    border: none;
    text-decoration: none;
    padding: 0px 0px 12px 10px;
    border-radius: 10px;
}

.senha .botoes .reenviar.col-xl-6 button{
    background: var(--secondary-color);
    color: white;
    padding: 5px 20px 7px 20px;
    border-radius: 22px;
    border: none;
}

.senha .botoes .confirmar.col-xl-6 button{
    background: var(--primary-color);
    color: white;
    padding: 5px 20px 7px 20px;
    border-radius: 22px;
    border: none;
}


