*{
    margin: 0px;
    padding: 0px;
}

.pagina-login{
    width: 100%;
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--dark-color);
    height: 100vh;
}

.pagina-login .logo{
    width: 100%;
}

.pagina-login .logo .col-xl-7{
    text-align: center;
    padding-top: 250px;
}

.pagina-login .logo img{
    width: 340px;
}

.pagina-login .cadastro.col-xl-5{
    padding: 100px 0px 0px 0px ;
}

.pagina-login .acesse-sua-conta{
    width: 341px;
    margin: auto;
    background-color: var(--secondary-color);
    border-radius: 10px 10px 0px 0px;
}

.pagina-login .acesse-sua-conta h1 {
    font-size: 28px;
    font-weight: 500px;
    color: white;
    padding: 19px 23px 18px 38px;
    margin: 0px;
}

.pagina-login .area-login{
    margin: auto;
    width: 77%;
    height: 90%;
    border-radius: 10px ;
    background: var(--primary-color) ;
}
.pagina-login .area-login p{
    padding: 28px 0px 3px 55px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.pagina-login .area-login input{
    margin-left: 55px;
    margin-top: 9px;
    border: none;
    border-radius: 10px;
    width: 307px;
    padding: 8px 0px 8px 14px;
    color: var(--dark-color);
    background: var(--input-color);
    font-size: 14px;
    font-weight: 400;
}

.pagina-login .senha p{
    padding: 6px 0px 3px 55px;
}

.toggle-switch .switch {
    background-color: white !important;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
    background-color: red !important;
}

.pagina-login .login {
    padding-top: 30px;
    padding-left: 20px;
}

.pagina-login .area-login button{
    border: none;
    margin-left: 56px;
    border-radius: 22px;
    width: 306px;
    margin-top: 27px;
    padding: 8px 0px 8px 0px;
    background: var(--secondary-color);
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    position: relative;
}

.pagina-login .area-login .logar p{
    padding: 13px 0px 0px 0px;
    text-align: center;
}

.pagina-login .criar-conta{
    width: 77%;
    margin: auto;
}

.pagina-login .criar-conta button{
    border: none;
    width: 88%;
    margin-top: 20px;
    margin-left: 26px;
    padding: 10px 10px 10px 10px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-color);
    background: white;
}

.pagina-login .senha {
    margin-top: 5px;
}

.new-password-area{
    display: flex;
    margin: 0 !important;
}

.new-password-area input {
    width: 70% !important;
    margin: 0 !important;
}

.transational-password-area{
    display: flex;
    margin: 0 !important;
}

.transational-password-area input {
    width: 70% !important;
    margin: 0 !important;
}

.first-access{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.first-access h1 {
    text-align: center;
    font-size: 32px;
}

.first-access h2 {
    text-align: center;
    font-size: 28px;
}

.first-access h6 {
    font-size: 12px;
    text-align: center;
}

.first-access .new-password-area .col-xl-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-access .transational-password-area .col-xl-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-access-confirm{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-access-confirm h1 {
    font-size: 32px;
    text-align: center;
}

.first-access-confirm h2 {
    font-size: 24px;
    text-align: center;
}

.first-access-confirm button {
    margin: 20px 0 0 0 !important;
}